import React, { useState, useEffect } from "react";
import { Form, Modal, Button, InputGroup } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Avatar from "avataaars";

const HomePage = () => {

  const history = useHistory()

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [refBy, setRefBy] = useState("");
  const [vidNo, setVidNo] = useState("");
  const [registeredDate, setRegisteredDate] = useState("");
  const [collectionDate, setCollectionDate] = useState("");
  const [recievedDate, setRecievedDate] = useState("");
  const [reportedDate, setReportedDate] = useState("");
  const [aadharOrPassportDetails, setAadharOrPassportDetails] = useState("")
  const [centerName, setCenterName] = useState("")
  const [sampleId, setSampleId] = useState("")
  const [refCustomer, setRefCustomer] = useState("")

  const [timeStampOfRegisteredDate, setTimeStampOfRegisteredDate] = useState("");
  const [timeStampOfCollectionDate, setTimeStampOfCollectionDate] = useState("");
  const [timeStampOfRecievedDate, setTimeStampOfRecievedDate] = useState("");
  const [timeStampOfReportedDate, setTimeStampOfReportedDate] = useState("");

  const [patientDetails, setPatientDetails] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [toggleGender, setToggleGender] = useState(false);
  const [toggleAvatar, setToggleAvatar] = useState(false);
  const [count, setCount] = useState(0);
  const [valueForAvatar, setValueForAvatar] = useState(0);
  const [updatedFields, setUpdatedFields] = useState([])


  const clotheList = [
    "Hoodie",
    "BlazerShirt",
    "BlazerSweater",
    "CollarSweater",
    "GraphicShirt",
    "ShirtVNeck",
  ];

  const accessoriesTypeList = [
    "Sunglasses",
    "Blank",
    "Kurt",
    "Prescription01",
    "Prescription02",
    "Round",
  ];

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const data = localStorage.getItem("patientDetails");
    const data2 = localStorage.getItem("valueForAvatar");
    if (data) {
      setPatientDetails(JSON.parse(data));
      const mydata = JSON.parse(data);
      setGender(mydata.gender);
    }
    if (data2) {
      setValueForAvatar(JSON.parse(data2));
    }
  }, []);

  useEffect(() => {

    const loginData = localStorage.getItem("credentials")
    if (JSON.parse(loginData) === "nammaWellness@admin") {
      //      
    } else {
      history.push("/login")
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
    localStorage.setItem("valueForAvatar", JSON.stringify(valueForAvatar));
    patientDetails
      &&
      patientDetails.gender === "Female"
      ?
      localStorage.setItem("getDataFrom", JSON.stringify("completePackageDataForFemale"))
      :
      localStorage.setItem("getDataFrom", JSON.stringify("completePackageData"))
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setToggleGender(false);
    setPatientDetails({
      name: updatedFields.includes("name") ? name : patientDetails.name,
      age: updatedFields.includes("age") ? age : patientDetails.age,
      gender: updatedFields.includes("gender") ? gender : patientDetails.gender,
      refBy: updatedFields.includes("refBy") ? refBy : patientDetails.refBy,
      registeredDate: updatedFields.includes("registeredDate") ? registeredDate : patientDetails.registeredDate,
      collectionDate: updatedFields.includes("collectionDate") ? collectionDate : patientDetails.collectionDate,
      reportedDate: updatedFields.includes("reportedDate") ? reportedDate : patientDetails.reportedDate,
      timeStampOfRegisteredDate: updatedFields.includes("timeStampOfRegisteredDate") ? timeStampOfRegisteredDate : patientDetails.timeStampOfRegisteredDate,
      timeStampOfCollectionDate: updatedFields.includes("timeStampOfCollectionDate") ? timeStampOfCollectionDate : patientDetails.timeStampOfCollectionDate,
      timeStampOfReportedDate: updatedFields.includes("timeStampOfReportedDate") ? timeStampOfReportedDate : patientDetails.timeStampOfReportedDate,
      centerName: updatedFields.includes("centerName") ? centerName : patientDetails.centerName,
      sampleId: updatedFields.includes("sampleId") ? sampleId : patientDetails.sampleId,
      refCustomer: updatedFields.includes("refCustomer") ? refCustomer : patientDetails.refCustomer
    });
    //localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
    setNextPage(false);
  };

  console.log("registeredDate is: ", registeredDate)

  // updating dates logic
  const addAmPmLogic = (dateValue, dateType) => {

    if (dateType === "registeredDate") {
      let modify1 = registeredDate ? registeredDate.slice(0, 17) + ` ${dateValue})` : patientDetails && patientDetails.registeredDate !== undefined ? patientDetails.registeredDate.slice(0, 17) + ` ${dateValue})` : ""
      setRegisteredDate(modify1)
      patientDetails.registeredDate = modify1
      setPatientDetails(patientDetails)
    }
    else if (dateType === "collectionDate") {
      let modify1 = collectionDate ? collectionDate.slice(0, 17) + ` ${dateValue})` : patientDetails && patientDetails.collectionDate !== undefined ? patientDetails.collectionDate.slice(0, 17) + ` ${dateValue})` : ""
      setCollectionDate(modify1)
      patientDetails.collectionDate = modify1
      setPatientDetails(patientDetails)
    }
    else if (dateType === "recievedDate") {
      let modify1 = recievedDate ? recievedDate.slice(0, 17) + ` ${dateValue})` : patientDetails && patientDetails.recievedDate !== undefined ? patientDetails.recievedDate.slice(0, 17) + ` ${dateValue})` : ""
      setRecievedDate(modify1)
      patientDetails.recievedDate = modify1
      setPatientDetails(patientDetails)
    }
    else if (dateType === "reportedDate") {
      let modify1 = reportedDate ? reportedDate.slice(0, 17) + ` ${dateValue})` : patientDetails && patientDetails.reportedDate !== undefined ? patientDetails.reportedDate.slice(0, 17) + ` ${dateValue})` : ""
      setReportedDate(modify1)
      patientDetails.reportedDate = modify1
      setPatientDetails(patientDetails)
    }

  }

  const randomAvatarHandler = () => {
    setToggleAvatar(true);
    if (count > 5) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  const resetDataHandler = () => {
    setPatientDetails({
      name: "",
      age: "",
      gender: "",
      sampleId: "",
      refBy: "",
      centerName : "",
      refCustomer : "",
      registeredDate: "",
      collectionDate: "",
      reportedDate: ""
    });
    localStorage.removeItem("valueForAvatar");
    localStorage.removeItem("page1Data");
    localStorage.removeItem("currentTestData");
    handleClose();
    window.location.reload()
  };

  const dateHandler1 = (theDate) => {

    setRegisteredDate(theDate)
  }

  const dateHandler2 = (theDate) => {

    setCollectionDate(theDate)
  }

  const dateHandler4 = (theDate) => {

    setReportedDate(theDate)
  }

  return (
    <div className="card p-4" style={{ backgroundColor: "#e6f2ff" }}>
      {/* Modal Start*/}
      <div>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>
                <i
                  style={{ color: "#e6e600" }}
                  className="fas fa-exclamation-triangle"
                ></i>{" "}
                Reset Confirmation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Resetting your patient information will erase all the data of the
              patient, including their tests. Are you sure you want to continue?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger btn-sm" onClick={resetDataHandler}>
                Confirm Reset
              </Button>
              <Button variant="primary btn-sm" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      <div className="text-center">
        {gender === "Male" ? (
          <div>
            <Avatar
              style={{ width: "100px", height: "100px" }}
              avatarStyle="Circle"
              topType="ShortHairShortFlat"
              accessoriesType={accessoriesTypeList[valueForAvatar]}
              hairColor="Brown"
              facialHairType="Blank"
              clotheType={clotheList[valueForAvatar]}
              clotheColor="Blue03"
              eyeType="Default"
              eyebrowType="Default"
              mouthType="Smile"
              skinColor="Pale"
            />
          </div>
        ) : (
          <div>
            <Avatar
              style={{ width: "100px", height: "100px" }}
              avatarStyle="Circle"
              accessoriesType={accessoriesTypeList[valueForAvatar]}
              facialHairType="Blank"
              clotheType={clotheList[valueForAvatar]}
              clotheColor="Blue03"
              eyeType="Default"
              mouthType="Default"
              skinColor="Pale"
            />
          </div>
        )}
      </div>

      <div className="text-center my-2">
        <button
          title="change"
          className="btn btn-info btn-sm button-focus-css mx-1"
          onClick={() => {
            randomAvatarHandler();
            setValueForAvatar(count);
          }}
        >
          <i className="fas fa-exchange-alt"></i>
        </button>

        <button
          title="default"
          className="btn btn-info btn-sm button-focus-css mx-1"
          onClick={() => {
            setToggleAvatar(false);
            setValueForAvatar(0);
          }}
        >
          <i className="fas fa-undo-alt"></i>
        </button>
      </div>

      <Form onChange={() => setNextPage(true)} onSubmit={submitHandler}>

        <div className="row">
          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Patient's Name</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="name"
              defaultValue={patientDetails ? patientDetails.name : name}
              onChange={(e) => {
                setName(e.target.value)
                setUpdatedFields([...updatedFields, "name"])
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Age</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="age"
              defaultValue={patientDetails ? patientDetails.age : age}
              onChange={(e) => {
                setAge(e.target.value)
                setUpdatedFields([...updatedFields, "age"])
              }}
            />
          </Form.Group>


          <Form.Group controlId="Radiogender" className="col mb-3">
            <Form.Label>
              <b>Gender:</b>
            </Form.Label>
            <span style={{ display: "flex" }}>
              <span style={{ marginTop: "5px" }}>Male</span>
              <span
                style={{
                  display: "flex",
                  marginLeft: "-15px",
                  marginRight: "22px",
                  marginTop: "5px",
                }}
              >
                <span onClick={() => setToggleGender(true)}>
                  <Form.Check id="radioMale">
                    <Form.Check
                      name="gender"
                      defaultValue="Male"
                      type="radio"
                      checked={
                        gender !== "Male" && gender !== "Female"
                          ? patientDetails.gender === "Male"
                          : toggleGender
                            ? gender === "Male"
                            : patientDetails.gender === "Male"
                      }
                      onChange={() => {
                        setGender("Male")
                        setUpdatedFields([...updatedFields, "gender"])
                      }}
                    />
                  </Form.Check>
                </span>
              </span>

              <span style={{ marginTop: "5px" }}>Female</span>
              <span
                style={{ display: "flex", marginLeft: "-15px", marginTop: "5px" }}
              >
                <span onClick={() => setToggleGender(true)}>
                  <Form.Check id="radioFemale">
                    <Form.Check
                      name="gender"
                      defaultValue="Female"
                      type="radio"
                      checked={
                        gender !== "Male" && gender !== "Female"
                          ? patientDetails.gender === "Female"
                          : toggleGender
                            ? gender === "Female"
                            : patientDetails.gender === "Female"
                      }
                      onChange={() => {
                        setGender("Female")
                        setUpdatedFields([...updatedFields, "gender"])
                      }}
                    />
                  </Form.Check>
                </span>
              </span>
            </span>
          </Form.Group>


          {/* <Form.Group className="col mb-3">
            <Form.Label>
              <b>VID No: / UHID</b>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="vid no"
                pattern="[0-9]+"
                defaultValue={patientDetails ? patientDetails.vidNo : vidNo}
                onChange={(e) => {
                  setVidNo(e.target.value)
                  setUpdatedFields([...updatedFields, "vidNo"])
                }}
              />
              <InputGroup.Text>{"/ HEALTH AVENUE"}</InputGroup.Text>
            </InputGroup>
          </Form.Group> */}


        </div>

        <div className="row">

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>{"Sample Id"}</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="sampleId"
              defaultValue={patientDetails ? patientDetails.sampleId : sampleId}
              onChange={(e) => {
                setSampleId(e.target.value)
                setUpdatedFields([...updatedFields, "sampleId"])
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Referred Dr.</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="refBy"
              defaultValue={patientDetails ? patientDetails.refBy : refBy}
              onChange={(e) => {
                setRefBy(e.target.value)
                setUpdatedFields([...updatedFields, "refBy"])
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Center</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="centerName"
              defaultValue={patientDetails ? patientDetails.centerName : centerName}
              onChange={(e) => {
                setCenterName(e.target.value)
                setUpdatedFields([...updatedFields, "centerName"])
              }}
            />
          </Form.Group>

        </div>

        {/* <div className="row">
          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Aadhar / Passport Details</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="details"
              defaultValue={patientDetails ? patientDetails.aadharOrPassportDetails : aadharOrPassportDetails}
              onChange={(e) => {
                setAadharOrPassportDetails(e.target.value)
                setUpdatedFields([...updatedFields, "aadharOrPassportDetails"])
              }}
            />
          </Form.Group>
        </div> */}

        <div className="row">

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Ref Customer</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ref customer"
              defaultValue={patientDetails ? patientDetails.refCustomer : refCustomer}
              onChange={(e) => {
                setRefCustomer(e.target.value)
                setUpdatedFields([...updatedFields, "refCustomer"])
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Registered Date</b>
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                type="datetime-local"
                placeholder=""
                defaultValue={patientDetails ? patientDetails.registeredDate : registeredDate}
                onChange={(e) => {
                  dateHandler1(e.target.value)
                  setUpdatedFields([...updatedFields, "registeredDate"])
                }}
              />
              <select style={{ width: "50px" }} onChange={(e) => {
                setTimeStampOfRegisteredDate(e.target.value)
                setUpdatedFields([...updatedFields, "timeStampOfRegisteredDate"])
              }} className="form-control">
                <option selected={patientDetails && patientDetails.timeStampOfRegisteredDate === ""} value={""}>-- --</option>
                <option selected={patientDetails && patientDetails.timeStampOfRegisteredDate === "am"} value={"am"}>AM</option>
                <option selected={patientDetails && patientDetails.timeStampOfRegisteredDate === "pm"} value={"pm"}>PM</option>
              </select>
            </div>
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Collection Date</b>
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                type="datetime-local"
                placeholder="name"
                defaultValue={patientDetails ? patientDetails.collectionDate : collectionDate}
                onChange={(e) => {
                  dateHandler2(e.target.value)
                  setUpdatedFields([...updatedFields, "collectionDate"])
                }}
              />
              <select style={{ width: "50px" }} onChange={(e) => {
                setTimeStampOfCollectionDate(e.target.value)
                setUpdatedFields([...updatedFields, "timeStampOfCollectionDate"])
              }} className="form-control">
                <option selected={patientDetails && patientDetails.timeStampOfCollectionDate === ""} value={""}>-- --</option>
                <option selected={patientDetails && patientDetails.timeStampOfCollectionDate === "am"} value={"am"}>AM</option>
                <option selected={patientDetails && patientDetails.timeStampOfCollectionDate === "pm"} value={"pm"}>PM</option>
              </select>
            </div>
          </Form.Group>

          {/* <Form.Group className="col mb-3">
            <Form.Label>
              <b>Recieved Date</b>
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                type="datetime-local"
                placeholder="name"
                defaultValue={patientDetails ? patientDetails.recievedDate : recievedDate}
                onChange={(e) => {
                  dateHandler3(e.target.value)
                  setUpdatedFields([...updatedFields, "recievedDate"])
                }}
              />
              <select style={{ width: "50px"}} onChange={(e) => {
                setTimeStampOfRecievedDate(e.target.value)
                setUpdatedFields([...updatedFields, "timeStampOfRecievedDate"])
              }} className="form-control">
                <option selected={patientDetails && patientDetails.timeStampOfRecievedDate === ""} value={""}>-- --</option>
                <option selected={patientDetails && patientDetails.timeStampOfRecievedDate === "am"} value={"am"}>AM</option>
                <option selected={patientDetails && patientDetails.timeStampOfRecievedDate === "pm"} value={"pm"}>PM</option>
              </select>
            </div>
          </Form.Group> */}

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Reported Date</b>
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                type="datetime-local"
                placeholder="name"
                defaultValue={patientDetails ? patientDetails.reportedDate : reportedDate}
                onChange={(e) => {
                  dateHandler4(e.target.value)
                  setUpdatedFields([...updatedFields, "reportedDate"])
                }}
              />
              <select style={{ width: "50px" }} onChange={(e) => {
                setTimeStampOfReportedDate(e.target.value)
                setUpdatedFields([...updatedFields, "timeStampOfReportedDate"])
              }} className="form-control">
                <option selected={patientDetails && patientDetails.timeStampOfReportedDate === ""} value={""}>-- --</option>
                <option selected={patientDetails && patientDetails.timeStampOfReportedDate === "am"} value={"am"}>AM</option>
                <option selected={patientDetails && patientDetails.timeStampOfReportedDate === "pm"} value={"pm"}>PM</option>
              </select>
            </div>
          </Form.Group>

        </div>

        <Link
          className="button-float-css"
          to={"/itemSelection/"}
          style={{ pointerEvents: nextPage === true ? "none" : "" }}
        >
          <button
            disabled={nextPage === true}
            className="btn btn-info btn-sm mt-3"
          >
            Next Page <i className="fas fa-arrow-alt-circle-right"></i>
          </button>
        </Link>

        <button
          type="submit"
          className="button-float-css btn btn-primary btn-sm mx-2 mt-3"
        >
          Save
        </button>

        <button
          onClick={handleShow}
          className="btn btn-outline-danger btn-sm mt-3 button-focus-css"
        >
          <i className="fas fa-undo-alt"></i> Reset User
        </button>
      </Form>
    </div>
  );
};

export default HomePage;
