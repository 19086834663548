// NOTE: when adding a new package make sure to remove the slice method from stateOptions 
// in itemSelectionScreen.js, REASON => avoiding "Package with all profiles" selection to appear in the page.

// male ref ranges
export const completePackageData = [
  {
    packageName: "Package 1",
    results: [
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Haemoglobin",
            low: "13.5",
            high: "18",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Leucocyte Count",
            low: "4000",
            high: "12000",
            unit: "cell/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "3.8",
            high: "4.8",
            unit: "mil/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "36",
            high: "46",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "83",
            high: "101",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27",
            high: "32",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.5",
            high: "34.5",
            unit: "gm/dL",
            result: "",
            method: "",
          },          
          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "37",
            high: "54",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Neutrophil",
            low: 40,
            high: 70,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 20,
            high: 40,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "2",
            high: "8",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Eosinophil",
            low: "1",
            high: "4",
            unit: "%",
            result: "",
            method: "",
          },         
          {
            test: "Basophil",
            low: "0",
            high: "1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "1.50",
            high: "4.50",
            unit: "Lac/cmm",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "9",
            high: "13",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.17",
            high: "0.35",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Absolute Neutrophils count",
            low: "2000",
            high: "7000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Lymphocytes count",
            low: "1000",
            high: "3000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Eosinophils count",
            low: "40",
            high: "440",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "200",
            high: "1000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Basophils count",
            low: "0.01",
            high: "0.10",
            unit: "10^3 /micro litre",
            result: "",
            method: "",
          },
          // {
          //   test: "LIC%",
          //   low: "0.4",
          //   high: "2.2",
          //   unit: "mg/g",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "LIC#",
          //   low: "0.4",
          //   high: "2.2",
          //   unit: "mg/g",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "P-LCC",
          //   low: "",
          //   high: "",
          //   unit: "fL",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Immature Granulocytes",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },         
          
          // {
          //   test: "Nucleated red blood cells",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Absolute Nucleated red blood cells",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Large Unstained Cell",
          //   low: "0",
          //   high: "4",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Absolute Large Unstained Cell",
          //   low: "0",
          //   high: "0.4",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Reticulocytes Count",
          //   low: "2",
          //   high: "5",
          //   unit: "%",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Content Haemoglobin (CH)",
          //   low: "22",
          //   high: "33",
          //   unit: "fL",
          //   result: "",
          //   method: "",
          // },
          // {
          //   "test": "G6PD (Quantitative)",
          //   "low": "6.97",
          //   "high": "20",
          //   "unit": "U/G Hb",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Sickle Cell Solubility Test-Sickling phenomenon",
          //   "low": "",
          //   "high": "",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   test: "ESR",
          //   low: "0",
          //   high: "20",
          //   unit: "mm/1sthr",
          //   result: "",
          //   method: "",
          // },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          // {
          //   "test": "HbA1c",
          //   "low": "4.5",
          //   "high": "6.4",
          //   "unit": "%",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "eAG (Estimated Average Glucose)",
          //   "low": "",
          //   "high": "",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          {
            "test": "Blood Sugar (Random)",
            "low": "70",
            "high": "140",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "74",
            "high": "100",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Blood Sugar (Postprandial)",
          //   "low": "0",
          //   "high": "140",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Blood Sugar (Random) 75 gm",
          //   "low": "0",
          //   "high": "200",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Blood Sugar (Random) 100 gm",
          //   "low": "0",
          //   "high": "200",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },          
          // {
          //   "test": "Amylase",
          //   "low": "0",
          //   "high": "80",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Insulin (Random)",
          //   "low": "1.9",
          //   "high": "23",
          //   "unit": "µIU/mL",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Insulin (Fasting)",
          //   "low": "60",
          //   "high": "110",
          //   "unit": "mg/dL",
          //   "result": "",
          //   "method": ""
          // },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 240,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0,
            high: 200,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 0,
            high: 40,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 100,
            high: 160,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          // {
          //   "test": "Plasma Glucose (Fasting)",
          //   "low": "70",
          //   "high": "110",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Apo A",
          //   "low": "107",
          //   "high": "205",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Apo B",
          //   "low": "52",
          //   "high": "129",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "APO B : APO A Ratio",
          //   "low": "0",
          //   "high": "0.8",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Creatine-Kinase",
          //   "low": "22",
          //   "high": "198",
          //   "unit": "U/L",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "HsCRP",
          //   "low": "0",
          //   "high": "5",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
        ],
      },
      {
        profilename: "Renal Function Test",
        investigation: [
          {
            test: "Serum Urea",
            low: "15",
            high: "50",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "0.7",
            high: "1.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },    
          {
            test: 'Glomerular Filtration Rate (eGFR)',
            low: '',
            high: '',
            unit: 'ml/min',
            result: '',
            method: ''
          },     
          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Calcium",
            low: "1",
            high: "1.32",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Uric Acid",
            low: "3.2",
            high: "7",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Chloride",
            low: "101",
            high: "109",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "3",
            high: "4.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          // {
          //   test: "Serum Albumin",
          //   low: "3.2",
          //   high: "4.6",
          //   unit: "g/dl",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Glomerular Filtration Rate",
          //   low: "15",
          //   high: "90",
          //   unit: "mL/min/1.73 m²",
          //   result: "",
          //   method: "",
          // },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "0.3",
            high: "1.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "0.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "SGPT",
            low: "0",
            high: "35",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "SGOT",
            low: "0",
            high: "33",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "53",
            high: "128",
            unit: "U/L",
            result: "",
            method: ""
          },
          // {
          //   test: "Gamma (GT)",
          //   low: "11",
          //   high: "61",
          //   unit: "U/L",
          //   result: "",
          //   method: ""
          // },
          {
            test: "Serum Total Protein",
            low: "6.6",
            high: "8.3",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: ""
          },
          // {
          //   test: "SGOT/SGPT Ratio",
          //   low: "",
          //   high: "",
          //   unit: "Ratio",
          //   result: "",
          //   method: ""
          // },
          // {
          //   test: "LDH Serum",
          //   low: "0",
          //   high: "248",
          //   unit: "U/L",
          //   result: "",
          //   method: ""
          // },
          {
            "test": "IRON-Serum",
            "low": "50",
            "high": "180",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "UIBC-Serum",
          //   "low": "120",
          //   "high": "470",
          //   "unit": "ug/dL",
          //   "result": "",
          //   "method": ""
          // },
          {
            "test": "Total Iron Binding Capicity",
            "low": "220",
            "high": "440",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Percentage Transferrin Saturation",
            "low": "30",
            "high": "60",
            "unit": "%",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Transferrin Serum",
          //   "low": "215",
          //   "high": "365",
          //   "unit": "mg/dL",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   test: "Ferritin",
          //   low: "12",
          //   high: "300",
          //   unit: "ng/mL",
          //   result: "",
          //   method: "",
          // },
        ]
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            "test": "VITAMIN B12",
            "low": "197",
            "high": "771",
            "unit": "pg/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "25 (OH) Vitamin D",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Vitamin B9 (Folic Acid)",
          //   "low": "4.6",
          //   "high": "34.8",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B6",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B1",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B2",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin C",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin A",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
        ]
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          // {
          //   "test": "T3",
          //   "low": "0.69",
          //   "high": "2.15",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "T4",
          //   "low": "52",
          //   "high": "127",
          //   "unit": "ng/mL",
          //   "result": "",
          //   "method": ""
          // },         
          {
            "test": "FT3",
            "low": "2.04",
            "high": "4.40",
            "unit": "pg/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "FT4",
            "low": "0.93",
            "high": "1.71",
            "unit": "ng/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "TSH",
            "low": "0.27",
            "high": "4.20",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Anti Thyroglobulin Abs",
          //   "low": "5",
          //   "high": "100",
          //   "unit": "IU/ml",
          //   "result": "",
          //   "method": ""
          // },

        ]
      },
      // {
      //   profilename: "COVID RT-PCR & ANTIBODY",
      //   investigation: [
      //     // {
      //     //   test: "COVID Antibody",
      //     //   low: "0.8",
      //     //   high: "440",
      //     //   unit: "cells/uL",
      //     //   result: "",
      //     //   method: "",
      //     // },
      //     {
      //       test: "COVID RT-PCR",
      //       low: "",
      //       high: "",
      //       unit: "",
      //       result: "",
      //       method: "",
      //     },
      //     {
      //       test: "Cycle Threshold N-gene",
      //       low: "",
      //       high: "",
      //       unit: "",
      //       result: "",
      //       method: "",
      //     },
      //     // {
      //     //   test: "COVID 19 IgM",
      //     //   low: "",
      //     //   high: "",
      //     //   unit: "",
      //     //   result: "",
      //     //   method: "",
      //     // },
      //   ]
      // },      
    ],
  },
  {
    packageName: "all profiles",
    results: [
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Haemoglobin",
            low: "13.5",
            high: "18",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Leucocyte Count",
            low: "4000",
            high: "12000",
            unit: "cell/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "3.8",
            high: "4.8",
            unit: "mil/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "36",
            high: "46",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "83",
            high: "101",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27",
            high: "32",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.5",
            high: "34.5",
            unit: "gm/dL",
            result: "",
            method: "",
          },          
          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "37",
            high: "54",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Neutrophil",
            low: 40,
            high: 70,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 20,
            high: 40,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "2",
            high: "8",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Eosinophil",
            low: "1",
            high: "4",
            unit: "%",
            result: "",
            method: "",
          },         
          {
            test: "Basophil",
            low: "0",
            high: "1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "1.50",
            high: "4.50",
            unit: "Lac/cmm",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "9",
            high: "13",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.17",
            high: "0.35",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Absolute Neutrophils count",
            low: "2000",
            high: "7000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Lymphocytes count",
            low: "1000",
            high: "3000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Eosinophils count",
            low: "40",
            high: "440",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "200",
            high: "1000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Basophils count",
            low: "0.01",
            high: "0.10",
            unit: "10^3 /micro litre",
            result: "",
            method: "",
          },
          {
            test: "LIC%",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "LIC#",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "P-LCC",
            low: "",
            high: "",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Immature Granulocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },         
          
          {
            test: "Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Large Unstained Cell",
            low: "0",
            high: "4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Large Unstained Cell",
            low: "0",
            high: "0.4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Reticulocytes Count",
            low: "2",
            high: "5",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Content Haemoglobin (CH)",
            low: "22",
            high: "33",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            "test": "G6PD (Quantitative)",
            "low": "6.97",
            "high": "20",
            "unit": "U/G Hb",
            "result": "",
            "method": ""
          },
          {
            "test": "Sickle Cell Solubility Test-Sickling phenomenon",
            "low": "",
            "high": "",
            "unit": "",
            "result": "",
            "method": ""
          },
          {
            test: "ESR",
            low: "0",
            high: "20",
            unit: "mm/1sthr",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          {
            "test": "HbA1c",
            "low": "4.5",
            "high": "6.4",
            "unit": "%",
            "result": "",
            "method": ""
          },
          {
            "test": "eAG (Estimated Average Glucose)",
            "low": "",
            "high": "",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random)",
            "low": "70",
            "high": "140",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "74",
            "high": "100",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Postprandial)",
            "low": "0",
            "high": "140",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random) 75 gm",
            "low": "0",
            "high": "200",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random) 100 gm",
            "low": "0",
            "high": "200",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },          
          {
            "test": "Amylase",
            "low": "0",
            "high": "80",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Insulin (Random)",
            "low": "1.9",
            "high": "23",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "Insulin (Fasting)",
            "low": "60",
            "high": "110",
            "unit": "mg/dL",
            "result": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 240,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0,
            high: 200,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 0,
            high: 40,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 100,
            high: 160,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "70",
            "high": "110",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Apo A",
            "low": "107",
            "high": "205",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Apo B",
            "low": "52",
            "high": "129",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "APO B : APO A Ratio",
            "low": "0",
            "high": "0.8",
            "unit": "",
            "result": "",
            "method": ""
          },
          {
            "test": "Creatine-Kinase",
            "low": "22",
            "high": "198",
            "unit": "U/L",
            "result": "",
            "method": ""
          },
          {
            "test": "HsCRP",
            "low": "0",
            "high": "5",
            "unit": "",
            "result": "",
            "method": ""
          },
        ],
      },
      {
        profilename: "Renal Function Test",
        investigation: [
          {
            test: "Serum Urea",
            low: "15",
            high: "50",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "0.7",
            high: "1.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },    
          {
            test: 'Glomerular Filtration Rate (eGFR)',
            low: '',
            high: '',
            unit: 'ml/min',
            result: '',
            method: ''
          },     
          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Calcium",
            low: "1",
            high: "1.32",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Uric Acid",
            low: "3.2",
            high: "7",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Chloride",
            low: "101",
            high: "109",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "3",
            high: "4.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "Glomerular Filtration Rate",
            low: "15",
            high: "90",
            unit: "mL/min/1.73 m²",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "0.3",
            high: "1.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "0.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "SGPT",
            low: "0",
            high: "35",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "SGOT",
            low: "0",
            high: "33",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "53",
            high: "128",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Gamma (GT)",
            low: "11",
            high: "61",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Total Protein",
            low: "6.6",
            high: "8.3",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: ""
          },
          {
            test: "SGOT/SGPT Ratio",
            low: "",
            high: "",
            unit: "Ratio",
            result: "",
            method: ""
          },
          {
            test: "LDH Serum",
            low: "0",
            high: "248",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            "test": "IRON-Serum",
            "low": "50",
            "high": "180",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "UIBC-Serum",
            "low": "120",
            "high": "470",
            "unit": "ug/dL",
            "result": "",
            "method": ""
          },
          {
            "test": "Total Iron Binding Capicity",
            "low": "220",
            "high": "440",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Percentage Transferrin Saturation",
            "low": "30",
            "high": "60",
            "unit": "%",
            "result": "",
            "method": ""
          },
          {
            "test": "Transferrin Serum",
            "low": "215",
            "high": "365",
            "unit": "mg/dL",
            "result": "",
            "method": ""
          },
          {
            test: "Ferritin",
            low: "12",
            high: "300",
            unit: "ng/mL",
            result: "",
            method: "",
          },
        ]
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            "test": "VITAMIN B12",
            "low": "197",
            "high": "771",
            "unit": "pg/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "25 (OH) Vitamin D",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B9 (Folic Acid)",
            "low": "4.6",
            "high": "34.8",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B6",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B1",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B2",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin C",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin A",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          {
            "test": "T3",
            "low": "0.69",
            "high": "2.15",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "T4",
            "low": "52",
            "high": "127",
            "unit": "ng/mL",
            "result": "",
            "method": ""
          },         
          {
            "test": "FT3",
            "low": "2.04",
            "high": "4.40",
            "unit": "pg/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "FT4",
            "low": "0.93",
            "high": "1.71",
            "unit": "ng/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "TSH",
            "low": "0.27",
            "high": "4.20",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "Anti Thyroglobulin Abs",
            "low": "5",
            "high": "100",
            "unit": "IU/ml",
            "result": "",
            "method": ""
          },

        ]
      },
      {
        profilename: "COVID RT-PCR & ANTIBODY",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ]
      },      
    ],
  },
];


// female ref ranges
export const completePackageDataForFemale = [
  {
    packageName: "Package 1",
    results: [
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Haemoglobin",
            low: "12.5",
            high: "16",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Leucocyte Count",
            low: "4000",
            high: "11000",
            unit: "cell/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "3.8",
            high: "4.8",
            unit: "mil/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "36",
            high: "46",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "83",
            high: "101",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27",
            high: "32",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.5",
            high: "34.5",
            unit: "gm/dL",
            result: "",
            method: "",
          },          
          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "37",
            high: "54",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Neutrophil",
            low: 40,
            high: 70,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 20,
            high: 40,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "2",
            high: "8",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Eosinophil",
            low: "1",
            high: "4",
            unit: "%",
            result: "",
            method: "",
          },         
          {
            test: "Basophil",
            low: "0",
            high: "1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "1.50",
            high: "4.50",
            unit: "Lac/cmm",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "9",
            high: "13",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.17",
            high: "0.35",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Absolute Neutrophils count",
            low: "2000",
            high: "7000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Lymphocytes count",
            low: "1000",
            high: "3000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Eosinophils count",
            low: "40",
            high: "440",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "200",
            high: "1000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Basophils count",
            low: "0.01",
            high: "0.10",
            unit: "10^3 /micro litre",
            result: "",
            method: "",
          },
          // {
          //   test: "LIC%",
          //   low: "0.4",
          //   high: "2.2",
          //   unit: "mg/g",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "LIC#",
          //   low: "0.4",
          //   high: "2.2",
          //   unit: "mg/g",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "P-LCC",
          //   low: "",
          //   high: "",
          //   unit: "fL",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Immature Granulocytes",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },         
          
          // {
          //   test: "Nucleated red blood cells",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Absolute Nucleated red blood cells",
          //   low: "",
          //   high: "",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Large Unstained Cell",
          //   low: "0",
          //   high: "4",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Absolute Large Unstained Cell",
          //   low: "0",
          //   high: "0.4",
          //   unit: "",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Reticulocytes Count",
          //   low: "2",
          //   high: "5",
          //   unit: "%",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Content Haemoglobin (CH)",
          //   low: "22",
          //   high: "33",
          //   unit: "fL",
          //   result: "",
          //   method: "",
          // },
          // {
          //   "test": "G6PD (Quantitative)",
          //   "low": "6.97",
          //   "high": "20",
          //   "unit": "U/G Hb",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Sickle Cell Solubility Test-Sickling phenomenon",
          //   "low": "",
          //   "high": "",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   test: "ESR",
          //   low: "0",
          //   high: "20",
          //   unit: "mm/1sthr",
          //   result: "",
          //   method: "",
          // },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          // {
          //   "test": "HbA1c",
          //   "low": "4.5",
          //   "high": "6.4",
          //   "unit": "%",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "eAG (Estimated Average Glucose)",
          //   "low": "",
          //   "high": "",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          {
            "test": "Blood Sugar (Random)",
            "low": "70",
            "high": "150",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "74",
            "high": "100",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Blood Sugar (Postprandial)",
          //   "low": "0",
          //   "high": "140",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Blood Sugar (Random) 75 gm",
          //   "low": "0",
          //   "high": "200",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Blood Sugar (Random) 100 gm",
          //   "low": "0",
          //   "high": "200",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },          
          // {
          //   "test": "Amylase",
          //   "low": "0",
          //   "high": "80",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Insulin (Random)",
          //   "low": "1.9",
          //   "high": "23",
          //   "unit": "µIU/mL",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Insulin (Fasting)",
          //   "low": "60",
          //   "high": "110",
          //   "unit": "mg/dL",
          //   "result": "",
          //   "method": ""
          // },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 240,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0,
            high: 200,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 0,
            high: 40,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 100,
            high: 160,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          // {
          //   "test": "Plasma Glucose (Fasting)",
          //   "low": "70",
          //   "high": "110",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Apo A",
          //   "low": "107",
          //   "high": "205",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Apo B",
          //   "low": "52",
          //   "high": "129",
          //   "unit": "mg/dl",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "APO B : APO A Ratio",
          //   "low": "0",
          //   "high": "0.8",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Creatine-Kinase",
          //   "low": "22",
          //   "high": "198",
          //   "unit": "U/L",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "HsCRP",
          //   "low": "0",
          //   "high": "5",
          //   "unit": "",
          //   "result": "",
          //   "method": ""
          // },
        ],
      },
      {
        profilename: "Renal Function Test",
        investigation: [
          {
            test: "Serum Urea",
            low: "15",
            high: "50",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "0.6",
            high: "1.3",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Calcium",
            low: "1",
            high: "1.32",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Uric Acid",
            low: "2.6",
            high: "6",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Chloride",
            low: "101",
            high: "109",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "3",
            high: "4.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          // {
          //   test: "Serum Albumin",
          //   low: "3.2",
          //   high: "4.6",
          //   unit: "g/dl",
          //   result: "",
          //   method: "",
          // },
          // {
          //   test: "Glomerular Filtration Rate",
          //   low: "15",
          //   high: "90",
          //   unit: "mL/min/1.73 m²",
          //   result: "",
          //   method: "",
          // },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "0.3",
            high: "1.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "0.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "SGPT",
            low: "0",
            high: "35",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "SGOT",
            low: "0",
            high: "33",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "42",
            high: "141",
            unit: "U/L",
            result: "",
            method: ""
          },
          // {
          //   test: "Gamma (GT)",
          //   low: "11",
          //   high: "61",
          //   unit: "U/L",
          //   result: "",
          //   method: ""
          // },
          {
            test: "Serum Total Protein",
            low: "6.6",
            high: "8.3",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: ""
          },
          // {
          //   test: "SGOT/SGPT Ratio",
          //   low: "",
          //   high: "",
          //   unit: "Ratio",
          //   result: "",
          //   method: ""
          // },
          // {
          //   test: "LDH Serum",
          //   low: "0",
          //   high: "248",
          //   unit: "U/L",
          //   result: "",
          //   method: ""
          // },
          {
            "test": "IRON-Serum",
            "low": "50",
            "high": "180",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "UIBC-Serum",
          //   "low": "120",
          //   "high": "470",
          //   "unit": "ug/dL",
          //   "result": "",
          //   "method": ""
          // },
          {
            "test": "Total Iron Binding Capicity",
            "low": "220",
            "high": "440",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Percentage Transferrin Saturation",
            "low": "30",
            "high": "60",
            "unit": "%",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Transferrin Serum",
          //   "low": "215",
          //   "high": "365",
          //   "unit": "mg/dL",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   test: "Ferritin",
          //   low: "12",
          //   high: "300",
          //   unit: "ng/mL",
          //   result: "",
          //   method: "",
          // },
        ]
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            "test": "VITAMIN B12",
            "low": "197",
            "high": "771",
            "unit": "pg/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "25 (OH) Vitamin D",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Vitamin B9 (Folic Acid)",
          //   "low": "4.6",
          //   "high": "34.8",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B6",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B1",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin B2",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin C",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "Vitamin A",
          //   "low": "20",
          //   "high": "100",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
        ]
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          // {
          //   "test": "T3",
          //   "low": "0.69",
          //   "high": "2.15",
          //   "unit": "ng/ml",
          //   "result": "",
          //   "method": ""
          // },
          // {
          //   "test": "T4",
          //   "low": "52",
          //   "high": "127",
          //   "unit": "ng/mL",
          //   "result": "",
          //   "method": ""
          // },         
          {
            "test": "FT3",
            "low": "2.04",
            "high": "4.40",
            "unit": "pg/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "FT4",
            "low": "0.93",
            "high": "1.71",
            "unit": "ng/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "TSH",
            "low": "0.27",
            "high": "4.20",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          // {
          //   "test": "Anti Thyroglobulin Abs",
          //   "low": "5",
          //   "high": "100",
          //   "unit": "IU/ml",
          //   "result": "",
          //   "method": ""
          // },

        ]
      },
      // {
      //   profilename: "COVID RT-PCR & ANTIBODY",
      //   investigation: [
      //     // {
      //     //   test: "COVID Antibody",
      //     //   low: "0.8",
      //     //   high: "440",
      //     //   unit: "cells/uL",
      //     //   result: "",
      //     //   method: "",
      //     // },
      //     {
      //       test: "COVID RT-PCR",
      //       low: "",
      //       high: "",
      //       unit: "",
      //       result: "",
      //       method: "",
      //     },
      //     {
      //       test: "Cycle Threshold N-gene",
      //       low: "",
      //       high: "",
      //       unit: "",
      //       result: "",
      //       method: "",
      //     },
      //     // {
      //     //   test: "COVID 19 IgM",
      //     //   low: "",
      //     //   high: "",
      //     //   unit: "",
      //     //   result: "",
      //     //   method: "",
      //     // },
      //   ]
      // },      
    ],
  },
  {
    packageName: "all Profiles",
    results: [
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Haemoglobin",
            low: "12.5",
            high: "16",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Leucocyte Count",
            low: "4000",
            high: "11000",
            unit: "cell/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "3.8",
            high: "4.8",
            unit: "mil/cu.mm",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "36",
            high: "46",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "83",
            high: "101",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27",
            high: "32",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.5",
            high: "34.5",
            unit: "gm/dL",
            result: "",
            method: "",
          },          
          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "37",
            high: "54",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Neutrophil",
            low: 40,
            high: 70,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 20,
            high: 40,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "2",
            high: "8",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Eosinophil",
            low: "1",
            high: "4",
            unit: "%",
            result: "",
            method: "",
          },         
          {
            test: "Basophil",
            low: "0",
            high: "1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "1.50",
            high: "4.50",
            unit: "Lac/cmm",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "9",
            high: "13",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.17",
            high: "0.35",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Absolute Neutrophils count",
            low: "2000",
            high: "7000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Lymphocytes count",
            low: "1000",
            high: "3000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Eosinophils count",
            low: "40",
            high: "440",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "200",
            high: "1000",
            unit: "/cumm",
            result: "",
            method: "",
          },
          {
            test: "Absolute Basophils count",
            low: "0.01",
            high: "0.10",
            unit: "10^3 /micro litre",
            result: "",
            method: "",
          },
          {
            test: "LIC%",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "LIC#",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "P-LCC",
            low: "",
            high: "",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Immature Granulocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },         
          
          {
            test: "Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Large Unstained Cell",
            low: "0",
            high: "4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Large Unstained Cell",
            low: "0",
            high: "0.4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Reticulocytes Count",
            low: "2",
            high: "5",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Content Haemoglobin (CH)",
            low: "22",
            high: "33",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            "test": "G6PD (Quantitative)",
            "low": "6.97",
            "high": "20",
            "unit": "U/G Hb",
            "result": "",
            "method": ""
          },
          {
            "test": "Sickle Cell Solubility Test-Sickling phenomenon",
            "low": "",
            "high": "",
            "unit": "",
            "result": "",
            "method": ""
          },
          {
            test: "ESR",
            low: "0",
            high: "20",
            unit: "mm/1sthr",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          {
            "test": "HbA1c",
            "low": "4.5",
            "high": "6.4",
            "unit": "%",
            "result": "",
            "method": ""
          },
          {
            "test": "eAG (Estimated Average Glucose)",
            "low": "",
            "high": "",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random)",
            "low": "70",
            "high": "150",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "74",
            "high": "100",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Postprandial)",
            "low": "0",
            "high": "140",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random) 75 gm",
            "low": "0",
            "high": "200",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Blood Sugar (Random) 100 gm",
            "low": "0",
            "high": "200",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },          
          {
            "test": "Amylase",
            "low": "0",
            "high": "80",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Insulin (Random)",
            "low": "1.9",
            "high": "23",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "Insulin (Fasting)",
            "low": "60",
            "high": "110",
            "unit": "mg/dL",
            "result": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 240,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0,
            high: 200,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 0,
            high: 40,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 100,
            high: 160,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            "test": "Plasma Glucose (Fasting)",
            "low": "70",
            "high": "110",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Apo A",
            "low": "107",
            "high": "205",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Apo B",
            "low": "52",
            "high": "129",
            "unit": "mg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "APO B : APO A Ratio",
            "low": "0",
            "high": "0.8",
            "unit": "",
            "result": "",
            "method": ""
          },
          {
            "test": "Creatine-Kinase",
            "low": "22",
            "high": "198",
            "unit": "U/L",
            "result": "",
            "method": ""
          },
          {
            "test": "HsCRP",
            "low": "0",
            "high": "5",
            "unit": "",
            "result": "",
            "method": ""
          },
        ],
      },
      {
        profilename: "Renal Function Test",
        investigation: [
          {
            test: "Serum Urea",
            low: "15",
            high: "50",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "0.6",
            high: "1.3",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Calcium",
            low: "1",
            high: "1.32",
            unit: "mEq/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Uric Acid",
            low: "2.6",
            high: "6",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Chloride",
            low: "101",
            high: "109",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "3",
            high: "4.5",
            unit: "mg/dl",
            result: "",
            method: "",
          },         
          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "Glomerular Filtration Rate",
            low: "15",
            high: "90",
            unit: "mL/min/1.73 m²",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "0.3",
            high: "1.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "0.2",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: ""
          },
          {
            test: "SGPT",
            low: "0",
            high: "35",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "SGOT",
            low: "0",
            high: "33",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "42",
            high: "141",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Gamma (GT)",
            low: "11",
            high: "61",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            test: "Serum Total Protein",
            low: "6.6",
            high: "8.3",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: ""
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: ""
          },
          {
            test: "SGOT/SGPT Ratio",
            low: "",
            high: "",
            unit: "Ratio",
            result: "",
            method: ""
          },
          {
            test: "LDH Serum",
            low: "0",
            high: "248",
            unit: "U/L",
            result: "",
            method: ""
          },
          {
            "test": "IRON-Serum",
            "low": "50",
            "high": "180",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "UIBC-Serum",
            "low": "120",
            "high": "470",
            "unit": "ug/dL",
            "result": "",
            "method": ""
          },
          {
            "test": "Total Iron Binding Capicity",
            "low": "220",
            "high": "440",
            "unit": "mcg/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "Percentage Transferrin Saturation",
            "low": "30",
            "high": "60",
            "unit": "%",
            "result": "",
            "method": ""
          },
          {
            "test": "Transferrin Serum",
            "low": "215",
            "high": "365",
            "unit": "mg/dL",
            "result": "",
            "method": ""
          },
          {
            test: "Ferritin",
            low: "12",
            high: "300",
            unit: "ng/mL",
            result: "",
            method: "",
          },
        ]
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            "test": "VITAMIN B12",
            "low": "197",
            "high": "771",
            "unit": "pg/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "25 (OH) Vitamin D",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B9 (Folic Acid)",
            "low": "4.6",
            "high": "34.8",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B6",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B1",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin B2",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin C",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "Vitamin A",
            "low": "20",
            "high": "100",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          {
            "test": "T3",
            "low": "0.69",
            "high": "2.15",
            "unit": "ng/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "T4",
            "low": "52",
            "high": "127",
            "unit": "ng/mL",
            "result": "",
            "method": ""
          },         
          {
            "test": "FT3",
            "low": "2.04",
            "high": "4.40",
            "unit": "pg/ml",
            "result": "",
            "method": ""
          },
          {
            "test": "FT4",
            "low": "0.93",
            "high": "1.71",
            "unit": "ng/dl",
            "result": "",
            "method": ""
          },
          {
            "test": "TSH",
            "low": "0.27",
            "high": "4.20",
            "unit": "µIU/mL",
            "result": "",
            "method": ""
          },
          {
            "test": "Anti Thyroglobulin Abs",
            "low": "5",
            "high": "100",
            "unit": "IU/ml",
            "result": "",
            "method": ""
          },

        ]
      },
      {
        profilename: "COVID RT-PCR & ANTIBODY",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ]
      },      
    ],
  },
]