import React from 'react'
import NavBar from './NavBar'
import HomePage from './HomePage'
import LoginPage from './LoginPage'
import DragDropCSV from './DragDropCSV'
import ProfileTests from './ProfileTests'
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ItemSelectionScreen from './ItemSelectionScreen'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// react alert package configrurations
const options = {
  timeout: 3500,
  position: positions.BOTTOM_CENTER
};


const App = () => {
  return (
    <div className="container">
      <Router>
        <NavBar />
        <Switch>
          <Provider className="mb-2" template={AlertTemplate} {...options}>
            <Route path="/" component={HomePage} exact />
            <Route path="/login" component={LoginPage} exact />
            <Route path="/itemSelection" component={ItemSelectionScreen} exact />
            <Route path="/profileTests" component={ProfileTests} exact />
            <Route path="/upload" component={DragDropCSV} exact />
          </Provider>
        </Switch>
      </Router>
    </div >
  )
}

export default App
