import React, { useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import { Table } from 'react-bootstrap';
import axios from 'axios'
import Message from "./Message"
import { Form, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';


const DragDropCSV = () => {

    let history = useHistory()

    const [myFile, setMyFile] = useState([])
    const [myRefRangesFile, setMyRefRangesFile] = useState([]);
    const [awsResponse, setAwsResponse] = useState("")
    const [pdfLinks, setPdfLinks] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [error, setError] = useState("")
    const [chooseFile, setChooseFile] = useState(false)
    const [userAllInfo, setAllUserInfo] = useState("")
    const [sendReportStatus, setSendReportStatus] = useState(false);
    const [refRangeRecord, setRefRangeRecord] = useState(null)
    const [refRangesStatus, setRefRangesStatus] = useState(false)
    const [refRangesUploadConfirm, setRefRangesUploadConfirm] = useState(false)
    const [showZipButton, setShowZipButton] = useState(false)
    const [count, setCount] = useState(0)

    useEffect(() => {

        const loginData = localStorage.getItem("credentials")
        if (JSON.parse(loginData) === "nammaWellness@admin") {
            //      
        } else {
            history.push("/login")
        }
    }, [])

    useEffect(() => {
        //
    }, [myFile])

    const downloadPDF = (myPdfDoc, emp_id, emp_name) => {
        console.log("the file getting downloaded")
        const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
        const downloadLink = document.createElement("a");
        const fileName = `${emp_id}-${emp_name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const downloadZip = () => {

        var zip = new JsZip();
        let allFiles = zip.folder('myfiles')

        for (let i = 0; i < pdfLinks.length; i++) {
            allFiles.file(`${userAllInfo[i].sampleid + '-' + userAllInfo[i].patientname}.pdf`, pdfLinks[i], { base64: true });
        }

        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "SmartReports.zip");
            });
    }


    const styles = { border: '3px dashed grey', width: "100%", color: 'black', padding: 20, backgroundColor: "#e6f7ff" };


    const valueHandler = (theValue) => {
        if (theValue !== undefined || theValue === "") {

            let modifyValue = ""

            let findJan = ""
            let findFeb = ""
            let findMar = ""
            let findApr = ""
            let findMay = ""
            let findJun = ""
            let findJul = ""
            let findAug = ""
            let findSep = ""
            let findOct = ""
            let findNov = ""
            let findDec = ""

            findJan = theValue.indexOf("Jan")
            findFeb = theValue.indexOf("Feb")
            findMar = theValue.indexOf("Mar")
            findApr = theValue.indexOf("Apr")
            findMay = theValue.indexOf("May")
            findJun = theValue.indexOf("Jun")
            findJul = theValue.indexOf("Jul")
            findAug = theValue.indexOf("Aug")
            findSep = theValue.indexOf("Sep")
            findOct = theValue.indexOf("Oct")
            findNov = theValue.indexOf("Nov")
            findDec = theValue.indexOf("Dec")

            if (findJan !== -1) {
                modifyValue = theValue.split("-")[0] + "-01"
                return modifyValue
            }
            else if (findFeb !== -1) {
                modifyValue = theValue.split("-")[0] + "-02"
                return modifyValue
            }
            else if (findMar !== -1) {
                modifyValue = theValue.split("-")[0] + "-03"
                return modifyValue
            }
            else if (findApr !== -1) {
                modifyValue = theValue.split("-")[0] + "-04"
                return modifyValue
            }
            else if (findMay !== -1) {
                modifyValue = theValue.split("-")[0] + "-05"
                return modifyValue
            }
            else if (findJun !== -1) {
                modifyValue = theValue.split("-")[0] + "-06"
                return modifyValue
            }
            else if (findJul !== -1) {
                modifyValue = theValue.split("-")[0] + "-07"
                return modifyValue
            }
            else if (findAug !== -1) {
                modifyValue = theValue.split("-")[0] + "-08"
                return modifyValue
            }
            else if (findSep !== -1) {
                modifyValue = theValue.split("-")[0] + "-09"
                return modifyValue
            }
            else if (findOct !== -1) {
                modifyValue = theValue.split("-")[0] + "-10"
                return modifyValue
            }
            else if (findNov !== -1) {
                modifyValue = theValue.split("-")[0] + "-11"
                return modifyValue
            }
            else if (findDec !== -1) {
                modifyValue = theValue.split("-")[0] + "-12"
                return modifyValue
            }
            else {
                return theValue
            }

        } else {
            return theValue
        }
    }

    const csvJSON = (csv) => {

        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        // var headers = lines[0].replace(/\s/g, "").split(",");

        console.log("headers\n", headers)

        for (var i = 1; i < lines.length; i++) {

            var obj = {};
            var currentline = lines[i].replace(/\r/g, "").split(",");

            //console.log("currrent lin\n", currentline)

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = valueHandler(currentline[j])
            }

            result.push(obj);

        }
        let json = JSON.stringify(result)
        return result; //JSON
    }

    let myArray = []
    let collectionOfJson = []

    const dataToRequiredJson = (data) => {

        console.log("data recieved\n", data)
        let requriedPatientDetails = {}

        for (let i = 0; i < data.length; i++) {

            let currentData = data[i]

            requriedPatientDetails = {
                "fileName": currentData["Sl.No"] + "-" + currentData["Patient Name"],
                "slNo": currentData["Sl.No"],
                "patientname": currentData["Patient Name"],
                "gender": currentData["Gender"],
                "age": currentData["Current Age"],
                "refBy": currentData["Ref By"],
                "registeredDate": currentData["Registered Date"],
                "collectionDate": currentData["Collection Date"],
                "ReportedDate": currentData["Reported Date"],
                "sampleId": currentData["Sample Id"],
                "centerName": currentData["Center Name"],
                "refCustomer": currentData["Ref Customer"],
                "client": "nammaWellness",
                "blankLetterHead": true,
                "results": [
                    {
                        "profilename": "undefined",
                        "investigation": []
                    }
                ]
            }


            delete data[i]["Sl.No"]
            delete data[i]["Patient Name"]
            delete data[i]["Gender"]
            delete data[i]["Current Age"]
            delete data[i]["Ref By"]
            delete currentData["Registered Date"]
            delete currentData["Collection Date"]
            delete currentData["Reported Date"]
            delete data[i]["Sample Id"]
            delete data[i]["Center Name"]
            delete data[i]["Ref Customer"]

            let restOfTheData = data[i]
            // console.log("restOfTheData\n", restOfTheData)

            // var p = {
            //     "p1": "value1",
            //     "p2": "value2",
            //     "p3": "value3"
            // };

            let collectionOfTests = []

            for (var key in restOfTheData) {

                if (restOfTheData.hasOwnProperty(key)) {
                    let theKey = key
                    let theLow = ""
                    let theHigh = ""
                    let theUnit = ""

                    if (refRangesStatus) {

                        // populating ref ranges and unit in test
                        for (let p = 0; p < refRangeRecord.length; p++) {
                            let theTest = refRangeRecord[p]["Test Name"]
                            if (theTest === theKey) {
                                //console.log("match found", theTest, "===", theKey)
                                theLow = refRangeRecord[p].Low
                                theHigh = refRangeRecord[p].High
                                theUnit = refRangeRecord[p]["Unit"]
                            } else {
                                //console.log("mismatch", theTest, "!==", theKey)
                            }
                        }

                        // modifying test json structure
                        let theResult = restOfTheData[key]
                        let testStructure = {
                            "test": theKey,
                            "low": theLow,
                            "high": theHigh,
                            "unit": theUnit,
                            "result": theResult,
                            "pastObservation": []
                        }

                        // avoid inserting empty tests
                        if (testStructure.result === "") {
                            //
                        } else {
                            collectionOfTests.push(testStructure)
                        }
                    } else {

                        let theResult = restOfTheData[key]
                        console.log("theResult: ", theResult)

                        let testStructure = {
                            "test": theKey,
                            "low": "",
                            "high": "",
                            "unit": "",
                            "result": theResult,
                            "pastObservation": []
                        }

                        // avoid inserting empty tests
                        if (testStructure.result === "") {
                            //
                        } else {
                            collectionOfTests.push(testStructure)
                        }
                    }
                }
            }


            // filling of tests
            requriedPatientDetails.results[0].investigation = collectionOfTests

            // console.log("here\n", requriedPatientDetails)

            if (requriedPatientDetails.slNo === "" || requriedPatientDetails.slNo === undefined) {
                //
            } else {
                collectionOfJson.push(requriedPatientDetails)
            }
        }

        setAllUserInfo(collectionOfJson)
        console.log("collection of json\n", collectionOfJson)


        setTimeout(() => {
            jsonHandler()
        }, 1500)
    }

    console.log("userAllInfo\n", userAllInfo)

    let myCurrentValue = 0
    let linksCollection = []

    useEffect(() => {
        //
    }, [count])

    console.log("count: ", count)

    const jsonHandler = async () => {

        var config = {
            method: 'POST',
            //url: "",
            url: 'https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/srportalbackend',
            headers: {
                'Content-Type': 'application/json'
            },
            data: collectionOfJson[myCurrentValue]
        };
        console.log("what im sending\n", collectionOfJson[myCurrentValue])

        try {
            const axiosData = await axios(config)
            console.log("inside axiosdata ===> \n\n", axiosData)
            //linksCollection.push(axiosData.data.base64Pdf)
            //setPdfLinks(linksCollection)
            downloadPDF(axiosData.data.EncodeData, collectionOfJson[myCurrentValue].slNo, collectionOfJson[myCurrentValue].patientname)

            if (myCurrentValue === collectionOfJson.length) {
                // nothing
                //downloadZip()
                setCount(collectionOfJson.length)
                setShowZipButton(true)
                setShowLoading(false)
                setError("")
            } else {
                myCurrentValue = myCurrentValue + 1
                setCount(myCurrentValue)
                setShowZipButton(true)
                setShowLoading(false)
                setShowLoading(true)
                jsonHandler()
            }

        } catch (error) {


            // downloadZip()
            setShowZipButton(true)
            console.log("error", error)
            //setError(error.message)
            setShowLoading(false)

            // setError("generating remaining ones")
            // console.log("error here: ", error)
            // if (myCurrentValue < collectionOfJson.length) {
            //     downloadZip()
            //     setError("generating remaining ones")
            //     setError("")
            //     var newData = []
            //     console.log("at this point", collectionOfJson.splice(0, 25))
            //     collectionOfJson.splice(0, 25)
            //     console.log("number of items removed:", myCurrentValue)
            //     console.log("new collection\n", collectionOfJson)
            //     console.log("newData\n", newData)
            //     myCurrentValue = 0
            //     //jsonHandler()                
            // } else {
            //     //setShowZipButton(true)
            //     console.log(error)
            // }
        }
    }


    // generate smart report
    const sendReport = () => {
        if (refRangesStatus && myRefRangesFile.length === 0) {
            setShowLoading(false)
            setError("Ref Ranges not provided!")
        } else {
            try {

                let files = myFile
                const formData = new FormData();
                formData.append('files[]', files[0])
                const reader = new FileReader();
                reader.onload = function (e) {
                    var contents = e.target.result;
                    const finalJson = csvJSON(contents)
                    dataToRequiredJson(finalJson)
                }
                reader.readAsText(files[0])

            } catch (error) {
                setError("File Error!")
                setShowLoading(false)
                setPdfLinks([])
            }
        }
    }


    // upload ref ranges
    const refRangesHandler = () => {
        try {

            let files = myRefRangesFile
            const formData = new FormData();
            formData.append('files[]', files[0])
            const reader = new FileReader();
            reader.onload = function (e) {
                var contents = e.target.result;
                const finalJson = csvJSON(contents)
                requiredRefRangesJson(finalJson)
            }
            reader.readAsText(files[0])
            setRefRangesUploadConfirm(true)

        } catch (error) {
            setError("File Error!")
            setShowLoading(false)
            setPdfLinks([])
        }
    }

    const requiredRefRangesJson = (refRangesData) => {
        console.log("ref ranges data", refRangesData)
        setRefRangeRecord(refRangesData)
    }

    const thedata = awsResponse !== "" ? awsResponse : "not found"

    console.log("refRangesStatus: ", refRangesStatus)

    return (
        <div>
            {error ?
                <Message variant='danger'>
                    {error}
                </Message>
                : ""}
            <div style={styles}>
                <FileDrop
                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                    onDragOver={(event) => console.log('onDragOver', event)}
                    onDragLeave={(event) => console.log('onDragLeave', event)}
                    onDrop={(files, event) => {
                        console.log('onDrop!', files, event)
                        setMyFile(files)
                        setPdfLinks([])
                        setError("")
                        console.log("my file data\n", myFile)
                    }}
                >
                    {myFile.length > 0 ?
                        <span>
                            <i class="fas fa-file-alt fa-3x text-primary mb-1"></i>
                            <div style={{ fontSize: "1.1rem" }}>
                                <b>File Recieved: </b> {myFile[0].name}
                            </div>
                        </span>
                        :
                        <div>
                            <span><i class="fas fa-cloud-upload-alt fa-3x text-primary"></i></span>
                            <div style={{ fontSize: "1.1rem", fontWeight: "600" }}>{"Drag & Drop your CSV file here"}</div>
                        </div>
                    }
                </FileDrop>
            </div>

            <div className='row'>

                <div className='col'>
                    <div style={{ margin: "10px 0px -10px 0px" }}>
                        <b>
                            Upload data Manually
                        </b>
                    </div>

                    {/* {chooseFile ? */}
                    <input
                        className="form-control mt-3"
                        id="fileupload"
                        type="file"
                        onChange={(e) => {
                            setMyFile(e.target.files)
                            setPdfLinks([])
                            setError("")
                        }}
                    />
                    {/* :
                    null
                } */}

                </div>

                <div className="col mb-4 button-focus-css">

                    <div style={{ margin: "10px 0px -10px 0px" }}>
                        <b>
                            Ref Ranges Status
                        </b>
                    </div>

                    {/* <select
                        onClick={(e) => setRefRangesStatus(e.target.value)}
                        className="form-select mt-3"
                        aria-label="select example"
                    >
                        <option value="false">{"-- --"}</option>
                        <option value="false">Without Ref Ranges</option>
                        <option value="true">With Ref Ranges</option>
                    </select> */}

                    <label className="mt-4">With Ref Ranges</label>
                    <input style={{ position: "relative", bottom: "-2px" }} type="radio" className="mt-4 mx-3" name={"refRanges"} onClick={() => setRefRangesStatus(true)} />
                    <label className="mt-4 mx-3">Without Ref Ranges</label>
                    <input style={{ position: "relative", bottom: "-2px" }} type="radio" name={"refRanges"} onClick={() => setRefRangesStatus(false)} />

                </div>
            </div>

            <Form.Group style={{ display: refRangesStatus ? "" : "none" }} className="mb-4 button-focus-css" onSubmit={(e) => e.preventDefault()}>

                <div style={{ margin: "10px 0px -10px 0px" }}>
                    <b>
                        Upload Ref Ranges
                    </b>
                </div>

                <input
                    className="form-control mt-3"
                    id="refRangesUpload"
                    type="file"
                    onChange={(e) => {
                        setMyRefRangesFile(e.target.files)
                        setError("")
                    }}
                />

                <button onClick={refRangesHandler} className="btn btn-sm btn-info mt-3">Save Ranges</button>

            </Form.Group>


            {/* 
            <button className="btn btn-info btn-sm button-focus-css" onClick={() => setChooseFile(!chooseFile)}>
                {chooseFile ? "Cancel Upload" : "Choose File"}
            </button> */}

            {showLoading ?

                <button className="btn btn-success btn-sm my-3" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {" "}Getting Link...
                </button>
                :
                <button
                    disabled={sendReportStatus || refRangesStatus && refRangesUploadConfirm === false}
                    onClick={() => {
                        setShowLoading(true)
                        sendReport()
                        setPdfLinks([])
                    }}
                    className="btn btn-success btn-sm my-3 button-focus-css">Get Smart Report <i className="far fa-file-pdf"></i></button>
            }

            <span style={{ marginLeft: "12px" }}>
                {" Total Reports Generated: "}
                {count} {"/"} {userAllInfo.length}
            </span>

            <div>

                {pdfLinks.length === 0 || pdfLinks === undefined ?
                    ""
                    :
                    // <span
                    //     className="button-hover-css text-primary"
                    //     onClick={() => downloadPDF(pdfLinks)}
                    //     style={{ marginTop: "18px" }}
                    // >
                    //     Donwload PDF
                    // </span>
                    <div>
                        <Table striped bordered className="text-center">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Sample Id</th>
                                    <th>Emp Name</th>
                                    <th>
                                        Report Link
                                        {showZipButton ?
                                            <span
                                                style={{ marginLeft: "8px" }}
                                                className="btn btn-outline-success btn-sm button-focus-css"
                                                onClick={downloadZip}>Zip all Reports <i class="far fa-file-archive"></i></span>
                                            : ""}
                                    </th>
                                </tr>
                            </thead>


                            {pdfLinks.map((eachRecord, idx) => (
                                <tbody>
                                    <tr key={idx + 1}>
                                        <td>{idx + 1}</td>
                                        <td>{userAllInfo[idx].sampleid}</td>
                                        <td>{userAllInfo[idx].patientname}</td>
                                        <td>
                                            <a href="#" onClick={() => downloadPDF(pdfLinks[idx], userAllInfo[idx].sampleid, userAllInfo[idx].patientname)}>
                                                download pdf <i className="fas fa-download"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </Table>

                    </div>
                }
            </div>

        </div>
    );
};


export default DragDropCSV